import React, { useEffect, useRef } from 'react';
import "./App.css";
import SliderComponent from "./components/Slidercomponent";
import AOS from 'aos';
import 'aos/dist/aos.css';
import cast from "./images/cast.png";
import casts from "./images/casts.png";
import muslim_population from "./images/muslim_population.jpg";
import mandir from "./images/mandir.png";
import pujari from "./images/pujari.png";
import gurukul from "./images/gurukull.jpg";
import farzi_baba from "./images/farzi_baba.png";
import population from "./images/population.png";
import love_jihad from "./images/love_jihad.png";
import kinnar from "./images/terririst.png";
import dhramantar from "./images/dhramantar.png";
import love_jihadi from "./images/love_jihadi.png";
import kattar_pandit from "./images/kattar_pandit.png";
import { Button } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TechnologySlider from './components/Home/TechnologySlider';
import WhyChooseUs from './components/Home/WhyChooseUs';


const featuresData = [
    {
        id: 1,
        image: mandir,
        title: "टैक्स मुक्त मंदिर",
        description: "बड़े मंदिरों में दान से आने वाला पैसा सरकार तक न जाए, बल्कि छोटे मंदिरों तक पहुँचे।",
        url: "/tax-free-temple",
    },

    {
        id: 2,
        image: gurukul,
        title: "गुरुकुल खुले",
        description: "मंदिरों से आने वाले पैसों से गुरुकुल खोले जाएं और गुरुकुल में पढ़ने वाले बच्चों की शिक्षा मुफ्त हो",
        url: "/free-gurukul-education",
    },
    {
        id: 3,
        image: kattar_pandit,
        title: "पुजारी को वेतन मिले",
        description: "गुरुकुल से पढ़े हुए और जिन्हें अपने सनातन धर्म का ज्ञान हो, उन पुजारियों को सरकार से वेतन मिले",
        url: "/pujari-work",
    },
];

const TopSection = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div>
            <SliderComponent />
            {/* <!-- SECTION TITLE --> */}
            <div className="row justify-content-center">
                <div className="col-md-10 col-lg-9">
                    <div className="section-title mb-70">
                        <h2 className="s-50 w-700">सनातन बोर्ड का उद्देश्य</h2>

                    </div>
                </div>
            </div>
            <div className="fbox-wrapper text-center">
                <div className="row row-cols-1 row-cols-md-3">
                    {featuresData.map((feature) => (
                        <div className="col" key={feature.id}>
                            <div className="dsfdsf">
                                <div className={`fbox-2 fb-${feature.id} wow fadeInUp`} style={{ visibility: "visible", animationName: "fadeInUp" }}>
                                    <div className="fbox-img gr--whitesmoke h-175">
                                        <img className="img-fluid" src={feature.image} alt="feature" />
                                    </div>
                                    <div className="fbox-txt">
                                        <h6 className="s-22 w-700">{feature.title}</h6>
                                        <p>{feature.description}</p>
                                    </div>
                                    <div className="text-center">
                                        <Link to={feature.url} className="btn btn--theme mt-5">अधिक जानकारी</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>


            {/********* our techonolgy section start **********/}
            <div className="row justify-content-center" style={{ marginTop: "25px" }}>
                <div className="col-md-10 col-lg-9">
                    <div className="section-title mb-70">
                        <h2 className="s-50 w-700">समस्याएँ</h2>

                    </div>
                </div>
            </div>
            {/* <TechnologySlider /> */}
            <div style={{ backgroundColor: '#FF9933' }}>
                <div className="main-techonology">
                    <section className="technology-section" data-aos="fade-left" data-aos-duration="1000">
                        <img src={population} alt="react" />
                        <h5 style={{ fontFamily: 'sans-serif', fontStyle: 'bold', textAlign: 'center' }}>बढ़ती जनसंख्या</h5>
                    </section>
                    <section className="technology-section" data-aos="fade-left" data-aos-duration="1000">
                        <img src={farzi_baba} alt="react" />
                        <h5 style={{ fontFamily: 'sans-serif', fontStyle: 'bold', textAlign: 'center' }}>ढोंगी बाबा</h5>
                    </section>
                    <section className="technology-section" data-aos="fade-left" data-aos-duration="1000">
                        <img src={love_jihad} alt="react" />
                        <h5 style={{ fontFamily: 'sans-serif', fontStyle: 'bold', textAlign: 'center' }}>लव जिहाद</h5>
                    </section>
                    <section className="technology-section" data-aos="fade-left" data-aos-duration="1000">
                        <img src={dhramantar} alt="react" />
                        <h5 style={{ fontFamily: 'sans-serif', fontStyle: 'bold', textAlign: 'center' }}>धर्मांतरण</h5>
                    </section>
                    <section className="technology-section" data-aos="fade-left" data-aos-duration="1000">
                        <img src={kinnar} alt="react" />
                        <h5 style={{ fontFamily: 'sans-serif', fontStyle: 'bold', textAlign: 'center' }}>कट्टरवाद आतंकवाद</h5>
                    </section>
                    <section className="technology-section" data-aos="fade-left" data-aos-duration="1000">
                        <img src={cast} alt="react" />
                        <h5 style={{ fontFamily: 'sans-serif', fontStyle: 'bold', textAlign: 'center' }}>जाति में विभाजन</h5>
                    </section>
                </div>
            </div>
            {/*        Why choose us start  */}

            <div className="row justify-content-center" style={{ marginTop: "25px" }}>
                <div className="col-md-10 col-lg-9">
                    <div className="section-title mb-70">
                        <h2 className="s-50 w-700"> </h2>
                    </div>
                </div>
            </div>

            {/* <WhyChooseUs /> */}
            {/* <h1 className="specification-heading">  Why Choose Us  </h1> */}
            <div className="main-specification">
                <section className="specification-section" data-aos="fade-right" data-aos-duration="1000">
                    <h1 className="text-big" style={{ fontSize: '45px', fontStyle: 'bold' }}>
                        धर्मांतरण और लव जिहाद
                    </h1>
                    <p className="text-small" style={{ fontSize: '22px', fontStyle: 'oblique' }}>
                        स्कूल और कॉलेज में पढ़ाई करने वाले छात्रों का अनुपात: लड़कियाँ हिन्दू धर्म की होती हैं, जबकि लड़के सभी धर्मों के होते हैं
                    </p>
                    <p className="text-small" style={{ fontSize: '22px', fontStyle: 'oblique' }}>
                        कंपनियों और बॉलीवुड इंडस्ट्री में लड़कियाँ हिन्दू धर्म की होती हैं, जबकि लड़के सभी धर्मों के होते हैं
                    </p>
                </section>
                {/*              vertical line between two section   */}
                <div className="vertical-line"></div>
                {/*               end section        */}
                <section className="specification-section">
                    <img src={love_jihadi} alt="slider1" data-aos="fade-left" data-aos-duration="1000" />
                </section>
            </div>


            <div className="main-specification">
                <section className="specification-section">
                    <img src={muslim_population} alt="slider2" data-aos="fade-right" data-aos-duration="1000" />
                </section>
                {/*              vertical line between two section   */}
                <div className="vertical-line"></div>
                {/*               end section        */}
                <section className="specification-section" data-aos="fade-left" data-aos-duration="1000">
                    <h1 className="text-big" style={{ fontSize: '45px', fontStyle: 'bold' }}>
                        बढ़ती जनसंख्या
                    </h1>
                    <p className="text-small" style={{ fontSize: '22px', fontStyle: 'oblique' }}>
                        दो से ज्यादा बच्चों का भी जन्म, सरकारी अस्पताल में मुफ्त में हो जाता है।
                    </p>
                    <p className="text-small" style={{ fontSize: '22px', fontStyle: 'oblique' }}>
                        कम उम्र के बच्चों को बिना शिक्षा के आसानी से काम मिल जाना भी जनसंख्या बढ़ने का मुख्य कारण है
                    </p>
                    <p className="text-small" style={{ fontSize: '22px', fontStyle: 'oblique' }}>
                        परिवार में जनसंख्या के अनुसार मुफ्त में राशन मिलता है
                    </p>

                </section>

            </div>

            <div className="main-specification">
                <section className="specification-section" data-aos="fade-right" data-aos-duration="1000">
                    <h1 className="text-big" style={{ fontSize: '45px', fontStyle: 'bold' }}>
                        जाति में विभाजन
                    </h1>
                    <p className="text-small" style={{ fontSize: '22px', fontStyle: 'oblique' }}>
                        सनातनी न केवल जाति में बंटा हुआ है बल्कि राज्य के आधार पर, भाषा के आधार पर, खान-पान के आधार पर, वेशभूषा के आधार पर और अमीर-गरीब के हिसाब से भी बंटा हुआ है
                    </p>
                    <p className="text-small" style={{ fontSize: '22px', fontStyle: 'oblique' }}>
                        वोट बैंक के लिए राजनेताओं द्वारा जाति में विभाजन करना
                    </p>
                </section>
                {/*              vertical line between two section   */}
                <div className="vertical-line"></div>
                {/*               end section        */}
                <section className="specification-section">
                    <img src={casts} alt="slider3" data-aos="fade-left" data-aos-duration="1000" />
                </section>
            </div>
        </div>
    );
}

export default TopSection;

