import iphone from "../../../images/iphone.png";
import bde_mandir from "../../../images/bde_mandir.png";
import mandir from "../../../images/bhikari.png";
import "./mobile.css";
import SignUp from "../../SignupForm/signup";
import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 

const AboutUs = () => {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
      setShowModal(true);
    };
  
    const closeModal = () => {
      setShowModal(false);
    };

    useEffect(() => {
      AOS.init();
      }, []);
  
    return (

        
        <div className="main-android-app">
          <div class="first-android-row">
        <div className="android-app-section" data-aos="fade-right" data-aos-duration="1000">
            <h3>टैक्स मुफ्त मंदिर</h3>
                  <h1 className="text-big" style={{fontSize: '50px'}}>
                   बड़े मंदिरों का छोटे मंदिरों से जुड़ाव
                   </h1>
                   <p className="text-small" style={{fontSize: '17px', fontStyle: 'oblique'}}>               
                    <h6>बड़े मंदिरों का छोटे मंदिरों से जुड़ाव होने पर गली-मोहल्ले में होने वाली सनातन विरोधी गतिविधियाँ जैसे धर्मांतरण, जातिगत विभाजन, बढ़ती जनसंख्या,  और मंदिरों की सुरक्षा के मामलों में भी सभी को संगठित करना और इसकी जानकारी सभी मंदिरों तक और सनातन बोर्ड के पुजारियों तक पहुँचानी चाहिए।</h6>
                   </p>
                   <button type="button" className="btn btn-primary" onClick={openModal}>सुझाव दें</button>
       </div>
       <div className="android-app-img-section" data-aos="fade-left" data-aos-duration="1000">
       <img src={bde_mandir}  alt="android" />
       </div>
       </div>

       {/* <div style={{ marginTop: '200px' }}> */}
       <div class="first-android-row">
       <div className="android-app-img-section" style={{ marginTop: '30px' }}>
       <img src={mandir}  alt="iphone"  data-aos="fade-right" data-aos-duration="1000" />
       </div>
       <div className="android-app-section"  data-aos="fade-left" data-aos-duration="1000">
       <h1 className="text-big" style={{fontSize: '50px'}}>
       कोलनी और गांव में घूमने वाले फर्जी बाबाओं से छुटकारा मिलेगा
                   </h1>
                   <p className="text-small" style={{fontSize: '17px', fontStyle: 'oblique'}}>               
                    <h6>फर्जी बाबाओं का गलियों में माँगना बंद होगा। यदि कोई सच में सनातन धर्म के लिए माँग रहा है, तो उस बाबा को गलियों से माँगना बंद करवा कर मंदिर में सनातन धर्म के लिए काम पर लगाना और सैलरी देना।</h6>
                   </p>
                   <button type="button" className="btn btn-primary" onClick={openModal}>सुझाव दें</button>
       </div>
       {showModal && <SignUp closeModal={closeModal} />}
       {/* </div> */}
       </div>
      </div>
      );
};

export default AboutUs;
