import React, { useEffect } from 'react';
import "./blog.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import mandir from "../../images/garibi.png";
import dhramantar from "../../images/dhramantar.png";
import sastra from "../../images/sastra.png";
import phere from "../../images/phere.jpg";

export const AboutUsComponent = () => {

};


const Blog = () => {
  useEffect(() => {
    AOS.init();
  }, []);


  return (
    <div className="main-blog">
       <div className="blog-section" data-aos="fade-right" data-aos-duration="1000" style={{ textAlign: 'center' }}>
       <h1>सनातन की रक्षा</h1>
       <img src={sastra} alt="wordpress"  style={{ width: '50%', height: 'auto' }}/>
        <h5 className="text-big" style={{ fontSize: '25px' }}>
        अन्य धर्मों के धर्मगुरुओं की तरह सनातन धर्म के धर्मगुरु को भी शस्त्र रखने की अनुमति होनी चाहिए।
        </h5>
        <h5 className="text-big" style={{ fontSize: '25px' }}>
        मंदिर का पुजारी किसी भी जाति का हो, लेकिन वह कट्टर हिंदू होना चाहिए, जिसे अपने सनातन धर्म का ज्ञान हो।
        </h5>
        <h5 className="text-big" style={{ fontSize: '25px' }}>
        धर्म विरोधी घटनाओं के लिए पुलिस पर निर्भर न होकर धर्म गुरुओं पर निर्भर होना
        </h5>
      </div>
     

      <div className="blog-section" data-aos="fade-left" data-aos-duration="1000" style={{ textAlign: 'center' }}>
      <h1>धर्मांतरण और लव जिहाद</h1>
        <img src={dhramantar} alt="ecommerce" style={{ width: '50%', height: 'auto' }} />
        <h5 className="text-big" style={{ fontSize: '25px' }}>
        स्कूल और कॉलेजों में सनातनी और गैर-सनातनी लड़कों और लड़कियों का अनुपात बनाए रखना।
        </h5>
        <h5 className="text-big" style={{ fontSize: '25px' }}>
        अभी सभी स्कूल और कॉलेजों में लड़कियाँ सनातनी होती हैं और लड़के सभी धर्मों के होते हैं।
        </h5>
        <h5 className="text-big" style={{ fontSize: '25px' }}>
        अगर अनुपात बनाए रखना संभव नहीं होता, तो लड़कियों के लिए अलग कॉलेज और लड़कों के लिए अलग कॉलेज होना चाहिए।
        </h5>
        {/* <p className="text-small" style={{ fontSize: '17px', fontStyle: 'oblique' }}>
          With the advent of new technologies and amid pandemic,
          it’s a great idea to reap the benefits of those technologies ....
        </p> */}
      </div>
      
      <div className="blog-section" data-aos="fade-right" data-aos-duration="1000" style={{ textAlign: 'center' }}>
      <h1>बढ़ती जनसंख्या पर नियंत्रण</h1>
        <img src={mandir} alt="social"  style={{ width: '50%', height: 'auto' }}/>
        <h5 className="text-big" style={{ fontSize: '25px' }} >
        फलों की दुकान, नाई की दुकान, पंचर लगाने की दुकान या किसी भी काम के लिए लाइसेंस अनिवार्य हो।
        </h5>
        <h5 className="text-big" style={{ fontSize: '25px' }} >
         जिनके तीन से ज्यादा बच्चे हों, उन्हें दुकान खोलने का लाइसेंस न मिले।
        </h5>
        <h5 className="text-big" style={{ fontSize: '25px' }} >
          सभी सनातनी भी अपनी निजी कंपनियों में तीन से ज्यादा बच्चे वालों को काम पर न रखें।
        </h5>
        {/* <p className="text-small" style={{ fontSize: '25px', fontStyle: 'oblique' }}>
          फलों की दुकान, नाई की दुकान, पंचर लगाने की दुकान या किसी भी काम के लिए लाइसेंस अनिवार्य हो।
         जिनके तीन से ज्यादा बच्चे हों, उन्हें दुकान खोलने का लाइसेंस न मिले।
         सभी सनातनी भी अपनी निजी कंपनियों में तीन से ज्यादा बच्चे वालों को काम पर न रखें।
        </p> */}
      </div>
      
      <div className="blog-section" data-aos="fade-left" data-aos-duration="1000" style={{ textAlign: 'center' }}>
        <h3>शादी और त्यौहारों को सनातन के अनुसार मनाना</h3>
        <img src={phere} alt="Ai"  style={{ width: '50%', height: 'auto' }}/>
        <h5 className="text-big" style={{ fontSize: '25px' }}>
        धार्मिक शोभा यात्रा और शादी में संगीत और बैंड वाले, धर्म को मानने वाले ही होने चाहिए।
        </h5>
        <h5 className="text-big" style={{ fontSize: '25px' }}>
        त्योहारों पर मंदिरों में लगने वाले मेले या अन्य कार्यक्रमों में दुकानें भी उसी मंदिर में आस्था रखने वालों की होनी चाहिए।
        </h5>
        <h5 className="text-big" style={{ fontSize: '25px' }}>
        दूसरे धर्म  में शादी करने वालों का पूरी तरह से धर्म के आधार पर बहिष्कार कर देना चाहिए
        </h5>
      </div>


    </div>
  );
};

export default Blog;