import React, { useState, useEffect, useRef } from "react";
import "./header.css";
import webcuts from "../../images/webcuts.png";
import mandir_icon from "../../images/mandir_icon.png";
import gurukul_icon from "../../images/gurukul_icon.png";
import pandit_icon from "../../images/pandit_icon.png";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Problems from "../AboutUs/about";
import Needs from "../ContactUs/contact";
import Solution from "../Blog/blog";
import Temple from "../Services/Apps/mobile";
import Gurukul from "../Services/Website/website";
import Pujari from "../Services/Ecommerce/ecommerce";
import TopSection from "../../List";
import mandir from "../../images/mandir.png";
import logo from "../../images/logo.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current && !menuRef.current.contains(event.target) &&
      dropdownRef.current && !dropdownRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
      setIsOpen(false);
    }
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="background">
        <nav className="navbar">
          <div className="logo">
            <img
              src={logo}
              alt="Logo"
            />
          </div>
          <ul ref={menuRef} className={`nav-list ${isMenuOpen ? 'open' : ''}`}>
            <li>
              <Link to="/" onClick={handleLinkClick}>सनातन बोर्ड</Link>
            </li>
            <li onClick={toggleDropdown} className="dropdown-toggle">
              लाभ
            </li>
            {isOpen && (
              <ul ref={dropdownRef} className="service-listing">
                <li>
                  <img src={mandir_icon} className="service-icon" />
                  <Link to="/tax-free-temple" className="dropdownOpen" onClick={handleLinkClick}>
                    <i className=""></i> टैक्स मुक्त मंदिर
                  </Link>
                </li>

                <li>
                  <img src={gurukul_icon} className="service-icon" />
                  <Link to="/free-gurukul-education" className="dropdownOpen" onClick={handleLinkClick}> <i className=""></i> गुरुकुल
                  </Link>
                </li>
                <li>
                  <img src={pandit_icon} className="service-icon" />
                  <Link to="/pujari-work" className="dropdownOpen" onClick={handleLinkClick}>    <i className=""></i> पुजारी का कार्य
                  </Link>
                </li>
              </ul>
            )}
            <li>
              <Link to="/problems" onClick={handleLinkClick}> समस्याएँ  </Link>
            </li>
            <li>
              <Link to="/solution" onClick={handleLinkClick}>समाधान </Link>
            </li>
            <li>
              <Link to="/needs" onClick={handleLinkClick}>आवश्यकता</Link>
            </li>
          </ul>
          <div className="hamburger" onClick={toggleMenu}>
            &#9776;
          </div>
        </nav>
      </div>
      <Routes>
        <Route path="/solution" element={<Solution />} />
        <Route path="/tax-free-temple" element={<Temple />} />
        <Route path="/free-gurukul-education" element={<Gurukul />} />
        <Route path="/pujari-work" element={<Pujari />} />
        <Route path="/needs" element={<Needs />} />
        <Route path="/problems" element={<Problems />} />
        <Route path='/' element={<TopSection />} />
      </Routes>

    </>

  );
};

export default Navbar;






